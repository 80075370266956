var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "sc-menu-submenu" },
    [
      _vm.menuData.disabled
        ? _c(
            "div",
            {
              staticClass: "sc-menu-submenu-title",
              style: {
                paddingLeft:
                  Number(_vm.indentCalculate) * _vm.inlevel + _vm.indentUnit,
              },
            },
            [
              _vm._t("submenuTitle", null, {
                data: {
                  info: _vm.menuData,
                  path: "" + _vm.inPath,
                },
              }),
            ],
            2
          )
        : _c(
            "div",
            {
              class: [
                "sc-menu-submenu-title",
                _vm.isHover ? "sc-menu-submenu-hover" : "",
                String(_vm.selectedItem[_vm.replaceFields.key]) ===
                String(_vm.menuData[_vm.replaceFields.key])
                  ? "sc-menu-submenu-active"
                  : "",
              ],
              on: {
                mouseenter: _vm.handleMouseEnter,
                mouseleave: _vm.handleMouseLeave,
                click: _vm.handleClick,
              },
            },
            [
              _vm._t("submenuTitle", null, {
                data: {
                  info: _vm.menuData,
                  path: "" + _vm.inPath,
                },
              }),
            ],
            2
          ),
      _c("scMenu", {
        staticClass: "sc-menu-inner",
        attrs: {
          "data-list": _vm.menuData[_vm.replaceFields.children],
          indent: _vm.indent,
          inlevel: _vm.inlevel + 1,
          "in-path": _vm.inPath,
          "selected-key": _vm.selectedKeyInner,
        },
        on: {
          "update:selectedKey": function ($event) {
            _vm.selectedKeyInner = $event
          },
          "update:selected-key": function ($event) {
            _vm.selectedKeyInner = $event
          },
          change: _vm.menuSelectChange,
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.$scopedSlots, function (index, name) {
              return {
                key: name,
                fn: function (data) {
                  return [_vm._t(name, null, null, data)]
                },
              }
            }),
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }